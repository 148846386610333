.favorite-type1 {
  color: #01c2b5;
  transition: 0.25s;
  .title-favorite {
    background: #01c2b5;
    color: white;
  }
}

.favorite-type1:hover {
  background-color: #01c2b5;
  color: #fff;
  .title-favorite {
    color: #01c2b5;
    background: white;
  }
}

.favorite-type2 {
  color: #00df70;
  transition: 0.25s;
  .title-favorite {
    background: #00df70;
    color: white;
  }
}

.favorite-type2:hover {
  background-color: #00df70;
  color: #fff;
  .title-favorite {
    color: #00df70;
    background: white;
  }
}

.favorite-type3 {
  color: #95b8fb;
  transition: 0.25s;
  .title-favorite {
    background: #95b8fb;
    color: white;
  }
}

.favorite-type3:hover {
  background-color: #95b8fb;
  color: #fff;
  .title-favorite {
    color: #95b8fb;
    background: white;
  }
}

.favorite-type4 {
  color: #482aa7;
  transition: 0.25s;
  .title-favorite {
    background: #482aa7;
    color: white;
  }
}

.favorite-type4:hover {
  background-color: #482aa7;
  color: #fff;
  .title-favorite {
    color: #482aa7;
    background: white;
  }
}

.favorite-type5 {
  color: #888888;
  transition: 0.25s;
  .title-favorite {
    background: #888888;
    color: white;
  }
}

.favorite-type5:hover {
  background-color: #888888;
  color: #fff;
  .title-favorite {
    color: #888888;
    background: white;
  }
}
