@import url("./styles/breakpoints.scss");
@import url("./styles/colors.scss");
@import url("./styles/dimensions.scss");
@import url("https://fonts.cdnfonts.com/css/raleway-5");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #00df70 !important;
}

* {
  font-family: "Poppins", sans-serif !important;
}

.h {
  @for $i from 1 to 100 {
    &-#{ $i } {
      height: #{$i}+ "%" !important;
    }
  }
}
.w {
  @for $i from 1 to 100 {
    &-#{ $i } {
      width: #{$i}+ "%" !important;
    }
  }
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc !important;
  border-radius: 4px !important;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5 !important;
  border-radius: 4px 4px 0px 0px !important;
}
