.btn {
  background: transparent;
  border: none;
  position: absolute;
  color: #f6f6f6;
  margin: 0 -7px;
  &:hover {
    background: transparent;
    border: none;
  }
}
.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background: transparent;
  border: none;
}
.message-visible {
  position: relative;
  border: #35db88 1px solid;
  background-color: #e4f8ee;
  box-shadow: 10px 20px 40px #00000029;
  color: #35db88;
}
.message-hidden {
  position: relative;
  border: #d1d1d1 1px solid;
  background-color: white;
  box-shadow: 10px 20px 40px #00000029;
  transition: 0.25s;
  &:hover {
    border: #35db88 1px solid;
    background-color: #e4f8ee;
    color: #35db88;
  }
}
.dropdown-menu.show {
  width: 300px !important;
  border: none;
  box-shadow: 10px 20px 40px #00000029;

    @media only screen and (max-height: 700px) {

    right: -90px;
  }
}
.notification-count {
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: #35db88;
  color: white;
  font-size: 12px;
  border-radius: 50%;
}
