.custom-modal .modal-dialog {
  height: auto;
  max-height: calc(100% - 1rem);
}
.custom-modal .modal-content {
  border-radius: 40px;
  overflow-x: hidden;
  overflow: hidden;
}
.custom-modal .modal-header .btn-close {
  border-radius: 50%;
  background-color: #ccc !important;
}
@media (max-width: 762px) {
  .modal-dialog {
    margin: 0;
    height: 100dvh;
  }
  .modal-content {
    border-radius: 0 !important;
    height: 100dvh;
  }
}
