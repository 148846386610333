.custom-modal .modal-dialog {
  height: auto;
  max-height: calc(100% - 1rem);
}
.custom-modal .modal-content {
  border-radius: 40px;
}
.custom-modal .modal-header .btn-close {
  border-radius: 50%;
  background-color: #ccc !important;
}
@media (max-width: 762px) {
  .modal-content {
    overflow: auto;
  }
  .input-group {
    flex-direction: column;
  }
  .input-group .form-control {
    width: 100% !important;
    margin-top: 10px;
  }
}
