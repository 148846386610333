.green {
  border-bottom: 15px solid #35db88;
  color: #35db88 !important;
}
.red {
  border-bottom: 15px solid red;
  color: red !important;
}
.gray {
  border-bottom: 15px solid #888888;
  color: #888888 !important;
}
.blue-gray {
  border-bottom: 15px solid #5b8fb9;
  color: #5b8fb9 !important;
}
.blue-puple {
  border-bottom: 15px solid #482aa7;
  color: #482aa7 !important;
}
.essential {
  border-bottom: 15px solid #01c2b5;
  color: #01c2b5 !important;
}

.green-status {
  background: #35db88;
}
.red-status {
  background: red;
}
.gray-status {
  background: #888888;
}
.skeleton-full-width {
  width: 100% !important;
  height: 100% !important;
}
