.modal-overlay {
  .fc-toolbar-chunk {
    display: flex !important;
    align-items: center !important;
  }

  .fc-prev-button {
    border-radius: 50%;
    color: black;
    padding: 0px 7px;
    border: 1px solid #21252965;
    margin-right: 10px;
    padding-left: 4px;
    padding-right: 5px;
    background: white !important;
    border-bottom-right-radius: 50% !important;
    border-top-right-radius: 50% !important;
    transition: 0.25s;
    box-shadow: none !important;

    &:hover {
      background: black !important;
      color: white !important;
    }
  }

  .fc-dayGridMonth-button,
  .fc-timeGridWeek-button {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 30px;
    color: black !important;
    font-weight: 600;
    border: none !important;
    box-shadow: none;
    background: #f0f0f2 !important;
    box-shadow: none !important;
    transition: 0.25 !important;
  }

  .fc-view-harness {
    @media (max-width: 762px) {
      height: 50dvh !important;
    }
  }

  .fc-customButton-button {
    background: black !important;
    color: white !important;
    border: none !important;
    box-shadow: none !important;
    padding: 3px 20px !important;
    transition: 0.25s;

    &:hover {
      background: white !important;
      border: 1px solid black !important;
      box-shadow: 10px 15px 40px #3b4b761f !important;
      @media (max-width: 762px) {
        box-shadow: none;
      }
      color: black !important;
    }
  }

  .fc-timeGridWeek-button {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  .fc-button-active {
    color: white !important;
    font-weight: 600;
    background: black !important;
    transition: 0.25s;
  }

  .fc-next-button {
    border-radius: 50%;
    color: black;
    border: 1px solid #21252965;
    padding: 0px 7px;
    margin-right: 10px;
    padding-left: 4px;
    padding-right: 5px;
    background: white !important;
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important;
    transition: 0.25s;
    box-shadow: none !important;

    &:hover {
      background: black !important;
      color: white !important;
    }
  }

  .fc-scrollgrid {
    border: none !important;
  }

  .fc .fc-daygrid-day.fc-day-today {
    background: none !important;
  }

  .fc-scrollgrid-sync-inner {
    text-align: center;
  }

  .fc-toolbar-title {
    text-transform: capitalize;
  }

  .fc-button {
    padding: 4px;
  }

  .fc-h-event {
    background: none !important;
    border: none !important;
  }

  .fc-theme-standard td,
  .fc-theme-standard th {
    border: none !important;
  }

  .fc-daygrid-day-top {
    justify-content: center !important;

    a {
      color: #000000;
      opacity: 0.5;
      font-size: 12px;
    }
  }

  .fc-event-main {
    display: flex;
    justify-content: center;

    img {
      width: 51px;
    }
  }

  a {
    color: gray;
    cursor: pointer;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
  }

  .fc-col-header-cell {
    border: none !important;

    a {
      color: #35db88;
    }
  }

  .close-button {
    top: 25px;
    right: 25px;
    cursor: pointer;
    background: #ececec 0% 0% no-repeat padding-box;
    border-radius: 50%;
    font-size: 1.2rem;
    color: #7a7a7a;
  }
}
.loading-screen {
  width: 100% !important;
}
