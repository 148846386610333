.type1 {
  background-color: #01c2b526;
  color: #01c2b5;
  transition: 0.25s;
}

.type1:hover {
  background-color: #01c2b5;
  color: #fff;
}

.type2 {
  background-color: #00df7026;
  color: #00df70;
  transition: 0.25s;
}

.type2:hover {
  background-color: #00df70;
  color: #fff;
}

.type3 {
  background-color: #95b8fb26;
  color: #95b8fb;
  transition: 0.25s;
}

.type3:hover {
  background-color: #95b8fb;
  color: #fff;
}

.type4 {
  background-color: #482aa726;
  color: #482aa7;
  transition: 0.25s;
}

.type4:hover {
  background-color: #482aa7;
  color: #fff;
}

.type5 {
  background-color: #88888826;
  color: #888888;
  transition: 0.25s;
}

.type5:hover {
  background-color: #888888;
  color: #fff;
}
.options-dropdown.dropdown-menu.show {
  width: auto !important;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
  color: #e2e2e2;
}
